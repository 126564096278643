#hero {  
    background: url("../assets/background.jpg") no-repeat fixed;
    background-repeat: no-repeat;
    background-position: right 100% bottom 60% !important;
    background-attachment: scroll;
    background-size: 120%;
  }
  
  @media only screen and (max-width: 768px) {
    #hero {
      background: url("../assets/background.jpg") no-repeat fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-repeat: no-repeat;
      background-position: right 64% bottom 50% !important;
      background-attachment: scroll;
      background-size: 350%;
    }
  }
  
  @media only screen and (max-width: 425px) {
    #hero {
      background: url("../assets/background.jpg") no-repeat fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-repeat: no-repeat;
      background-position: right 65% bottom 70% !important;
      background-attachment: scroll;
      background-size: 350%;
    }
  }
  
  
  