nav {
    background-color: rgb(208,204,208);
    padding:0 !important;
}

li{
    cursor:pointer;
}

button{
    border:none !important;
}
button:active,button:hover{
    border:none !important;
}