a{
    text-decoration:none;
  }
  
  #footer{
    background-color:rgb(36,39,43);
    color:rgb(251,252,255);
  }
  
  #footerButton{ 
    color:rgb(27,94,228);
  }
  
  #footerButton:hover{ 
    color:rgb(20,68,163);
  }