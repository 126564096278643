#clients{
    background-color:rgb(208,204,208);
  }
  
  #carouselcard{
    background-color:rgb(224, 220, 224);
  }

  #LampadinhaImg {
    width: 80%;
  }