body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


#button1{
  background-color: rgb(36,39,43);
  color:rgb(251,252,255);
  border: none;
}

#button1:hover{
  background-color:rgb(251,252,255);
  color:rgb(36,39,43);
  border: none;
}

#button1:focus{
  border:none;
  outline:0;
  box-shadow: none
}

#button2{
  background-color:rgb(20,68,163);
  color:rgb(251,252,255);
  border: none;
}

#button2:hover{
  background-color:rgb(251,252,255);
  color:rgb(20,68,163);
  border: none;
}

#button2:focus{
  border:none;
  outline:0;
  box-shadow: none
}



.back-to-top {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width:2.75rem;  
  height:2.75rem;
  background: rgba(90, 92, 105, 0.5);
  color: #fff;
  cursor: pointer;  
  border: none;
  line-height: 46px;
}

.back-to-top:hover {
  background: #5a5c69;
}

.back-to-top:hover {
  color: white;
}