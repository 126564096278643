#aboutGrafismo{
  color:rgb(208,204,208);
  background-color:rgb(36,39,43);   
  background-image: url("../assets/moedas.png"), url("../assets/grafismo.png");
  background-repeat: no-repeat, repeat-x;
  background-position: right 170% bottom 100%, top left;
  background-size: 200%, 70% 15%;
}

@media only screen and (max-width: 768px) {
  #aboutGrafismo{
    color:rgb(208,204,208);
    background-color:rgb(36,39,43); 
    background-image: url("../assets/moedas.png"),url("../assets/grafismo.png");
    background-repeat: no-repeat, no-repeat;
    background-position: right 127% bottom 100%, top left;
    background-size: 410%, 100%;
  }
}

@media only screen and (max-width: 425px) {
  #aboutGrafismo{
    color:rgb(208,204,208);
    background-color:rgb(36,39,43);
    background-image: url("../assets/moedas.png"), url("../assets/grafismo.png");
    background-repeat: no-repeat, no-repeat;
    background-position: right 127% bottom 100%, top left;
    background-size: 400%, 100%;
  }
}

  