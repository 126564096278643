#servicesBg {
  background-color: rgb(36, 39, 43);
  color: rgb(208, 204, 208);
  position:relative;
}

#grafismo{
  position:absolute;
  max-width: 100%;
  height:28vh;
  padding-top:5.5rem;
  bottom:0 !important;
  
}

@media only screen and (max-width: 768px) {
  #grafismo{
    max-width: 100%;
    padding-top: 3rem;
    height:15vh;
    
  }
}

@media only screen and (max-width: 425px) {
  #grafismo{
    max-width: 100%;
    padding-top: 3rem;
    height:12vh;
    
  }
}

#services {
  background-color: rgb(208, 204, 208);
  background-size: 100%;
  color: rgb(36, 39, 43);
}

#h1-services {
  text-align: end;
}

ul {
  list-style-type: none;
}

#ul-servicos {
  height: 50vh;
  color: rgb(36, 39, 43);
  background-color: rgb(208, 204, 208);
  padding: 3rem;
  margin-top: 5rem;
}

#li-services {
  padding-right: 2rem;
  padding-top: 3rem;
  font-size: 20px;
  text-align: end;
}

#p-services {
  text-align: justify;
}

#span-services {
  border-bottom: 3px solid rgb(20, 68, 163);
}

